import { generateGridAsSVG, generatorUtils, GridDesign } from '@gelatoas/design-editor-calendar';

import { CalendarEvent, MediaGrid, MediaGridInfo } from 'editor/src/store/design/types';
import { LayoutFrame } from 'editor/src/store/editorModules/layouts/types';

import { elementUuids } from 'editor/src/util/uuids';

import createGridHash from '../design/createGridHash';

import { getCalendarEventsPerMonth } from './getCalendarEventsPerMonth';

const createGridMediaElement = (
  frame: LayoutFrame,
  gridInfo: MediaGridInfo,
  gridDesign: GridDesign,
  spreadWidth: number,
  calendarEvents?: CalendarEvent[],
): MediaGrid => {
  const d2f = generatorUtils.getToSizeFn(gridDesign, spreadWidth);
  const { locale } = gridInfo;
  const monthEvents = getCalendarEventsPerMonth(gridInfo.gridDate, locale, calendarEvents);

  return {
    type: 'grid',
    group: '',
    name: 'grid',
    x: frame.x,
    y: frame.y,
    width: frame.width,
    height: frame.height,
    r: 0,
    uuid: elementUuids.generate(),
    content: generateGridAsSVG(gridDesign, gridInfo.gridDate, locale, frame.width, frame.height, monthEvents, {
      d2f,
      firstDayOfWeek: gridInfo.firstDayOfWeek,
    }),
    personalizationLocked: true,
    unduplicable: true,
    grid: gridInfo,
    createdByLayout: true,
    layoutFrameId: frame.id,
    gridHash: createGridHash(gridInfo, monthEvents),
  };
};

export default createGridMediaElement;
