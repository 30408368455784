import { designs } from '@gelatoas/design-editor-calendar';
import cloneDeep from 'lodash/cloneDeep';

import {
  CalendarEvent,
  MediaElement,
  MediaGrid,
  MediaImage,
  MediaLine,
  MediaText,
  Spread,
} from 'editor/src/store/design/types';
import { LayoutFrame, LayoutFrameArea } from 'editor/src/store/editorModules/layouts/types';

import applyScaleToImageElement from './applyScaleToImageElement';
import applyScaleToLineElement from './applyScaleToLineElement';
import applyScaleToRectangleElement from './applyScaleToRectangleElement';
import findElementFrame from './findElementFrame';
import reapplyFrameToTextElement, { MediaContext } from './reapplyFrameToTextElement';
import scaleTextElement from './scaleTextElement';
import updateGridElement from './updateGridElement';
import updateImageElement from './updateImageElement';
import { Mode, RectMediaElement } from './utils';

export interface Box {
  x: number;
  y: number;
  width: number;
  height: number;
}

function reflectMediaElement<Elt extends MediaElement>(
  sourceMediaBox: Box,
  destMediaBox: Box,
  sourceLayoutFrames: LayoutFrame<LayoutFrameArea>[] | undefined,
  destLayoutFrames: LayoutFrame<LayoutFrameArea>[] | undefined,
  sourceSpread: Spread,
  destSpread: Spread,
  destSpreadWidth: number,
  sourceElement: Elt,
  mode: Mode,
  context: MediaContext | undefined,
  calendarEvents?: CalendarEvent[],
): Elt {
  const destElement = cloneDeep(sourceElement);
  const pageOriginElement = sourceElement.pageOrigin || {
    width: sourceMediaBox.width,
    height: sourceMediaBox.height,
  };
  destElement.pageOrigin = pageOriginElement;

  const frame =
    sourceLayoutFrames && destLayoutFrames && findElementFrame(sourceLayoutFrames, destLayoutFrames, sourceElement);

  // we first update the element position and dimensions
  if (frame && destElement.type !== 'line') {
    // if the element is part of a layout, we reapply the layout, meaning the aspect ratio will probably be changed
    destElement.x = frame.destFrame.x;
    destElement.y = frame.destFrame.y;
    destElement.width = frame.destFrame.width;
    destElement.height = frame.destFrame.height;

    if (destElement.type === 'text') {
      const sourceText = sourceElement as MediaText;
      destElement.layoutFrameId = sourceText.layoutFrameId ?? frame.sourceFrame.id;
      destElement.sourceFrame = sourceText.sourceFrame ?? {
        width: frame.sourceFrame.width,
        height: frame.sourceFrame.height,
      };
      if (context) {
        reapplyFrameToTextElement(frame.destFrame, sourceSpread, destSpread, destSpreadWidth, destElement, context);
      } else {
        const destScale = Math.min(
          frame.destFrame.width / destElement.sourceFrame.width,
          frame.destFrame.width / destElement.sourceFrame.height,
        );
        const originScale = Math.min(
          frame.sourceFrame.width / destElement.sourceFrame.width,
          frame.sourceFrame.width / destElement.sourceFrame.height,
        );
        const scale = destScale / originScale;
        scaleTextElement(sourceText, destElement, scale);
      }
    }
  } else {
    // element is not part of a layout, we keep the same aspect ratio and scale everything proportionall
    const destScale = Math.min(
      destMediaBox.width / pageOriginElement.width,
      destMediaBox.height / pageOriginElement.height,
    );
    const originScale = Math.min(
      sourceMediaBox.width / pageOriginElement.width,
      sourceMediaBox.height / pageOriginElement.height,
    );
    const scale = destScale / originScale;

    if (destElement.type === 'line') {
      const scaleX = destMediaBox.width / sourceMediaBox.width;
      const scaleY = destMediaBox.height / sourceMediaBox.height;
      applyScaleToLineElement(sourceElement as MediaLine, destElement, scale, scaleX, scaleY);
    } else {
      if (destElement.type === 'image') {
        applyScaleToImageElement(sourceElement as RectMediaElement, sourceMediaBox, destElement, destMediaBox, scale);
      } else {
        applyScaleToRectangleElement(
          sourceElement as RectMediaElement,
          sourceMediaBox,
          destElement,
          destMediaBox,
          scale,
        );
      }

      if (destElement.type === 'text') {
        scaleTextElement(sourceElement as MediaText, destElement, scale);
      }
    }
  }

  // we update each element specific properties
  if (sourceElement.type === 'image') {
    updateImageElement(sourceElement, destElement as MediaImage, mode);
  } else if (sourceElement.type === 'grid') {
    updateGridElement(
      sourceElement,
      destElement as MediaGrid,
      designs.gridDesigns,
      sourceSpread,
      destSpreadWidth,
      calendarEvents,
    );
  }

  return destElement;
}

export default reflectMediaElement;
