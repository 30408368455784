import { PayloadAction } from '@reduxjs/toolkit';

import { CalendarEvent, DesignState } from 'editor/src/store/design/types';

const addOrUpdateCalendarEventsReducer = (state: DesignState, action: PayloadAction<CalendarEvent[]>) => {
  if (!state.designData?.calendar?.events) {
    return;
  }

  const eventsList = state.designData.calendar.events;
  const existingEventsIds = eventsList.flatMap(({ id }) => id);
  const newEvents = action.payload.filter(({ id }) => !existingEventsIds.includes(id));

  state.designData.calendar.events = eventsList.map((originEvent) => {
    const modifiedEvent = action.payload.find((event) => event.id === originEvent.id);
    return modifiedEvent || originEvent;
  });

  newEvents.forEach((newEvent) => {
    state.designData?.calendar?.events?.push(newEvent);
  });
};

export default addOrUpdateCalendarEventsReducer;
