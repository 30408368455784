import './index.module.scss';
import 'react-redux-toastr/src/styles/index.scss';
import 'array.prototype.flat/auto';
import 'object.values/auto';
import * as Sentry from '@sentry/react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import React, { Suspense, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';

import openPopupOperation from 'editor/src/store/app/module/popup/operation/openPopupOperation';
import { DesignData } from 'editor/src/store/design/types';
import setHostSettingsOperation from 'editor/src/store/hostSettings/operation/setHostSettingsOperation';
import { ECommerceVariant } from 'editor/src/store/variants/types';

import getCustomerDeviceType from 'editor/src/util/getCustomerDeviceType';

import DropDownContainer from 'editor/src/component/DropDown/DropDownContainer';
import { BottomBarHeightProvider } from 'editor/src/component/EditorArea/EditorAreaControls/BottomControls/BottomBarHeightProvider';
import { ConfirmationOption } from 'editor/src/component/Popup/LocalConfirmationPopup';
import { PopupName } from 'editor/src/component/Popup/popups';
import { mobileDevices } from 'editor/src/component/useDetectDeviceType';
import TooltipContainer from 'editor/src/component/WithTooltip/TooltipContainer';

import CartItemDescription from './component/CartItemDescription';
import CtaButtonLabel, { DefaultCtaButtonText } from './component/CtaButtonLabel/CtaButtonLabel';
import DraftSidebar from './component/DraftSidebar';
import EditCartItem from './component/EditCartItem';
import OpenDraftsItem from './component/OpenDraftsItem';
import PersonalizationView, { UIOverrides, VariantImages } from './component/PersonalizationView';
import PersonalizerConfirmationPopup from './component/PersonalizerConfirmationPopup';
import PreviewView from './component/PreviewView';
import previewAssetAPIEmbedded from './component/PreviewView/SpreadRenderer3D/embeddedPreviewAssetAPI';
import { PreviewAssetAPIContext } from './component/PreviewView/SpreadRenderer3D/PreviewAssetAPI';
import ShippingPrice from './component/ShippingPrice';
import StaticPreviewView from './component/StaticPreviewView';
import * as draftAPI from './draftAPI';
import { createDraft } from './draftAPI';
import initSentry from './initSentry';
import languageMap from './locale';
import store, { i18n } from './store';
import { toggleSidebarAction } from './store/drafts/slice';
import { getGuestCustomerId } from './store/drafts/utils/guestStorage';
import { setSelectedPreviewIndexAction } from './store/productPersonalizer/slice';
import fetchAndLoadDummyDesign from './utils/fetchAndLoadDummyDesign';
import getPreviewUrl from './utils/getPreviewUrl';
import getVariantImages from './utils/getVariantImages';
import { fetchAndLoadDesign, fetchDesignFromId, fetchShippingPrices, setProductImages } from './utils/loadData';
import { Product, Variant } from './utils/productTypes';
import saveDesign from './utils/saveDesign';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

initSentry(urlParams);
let currentVariantId: string | undefined;
let gelatoVariantId: string | undefined;

store.dispatch(setHostSettingsOperation({ ignoreDesignPlugins: true }));

async function loadProduct(
  product: Product,
  selectedVariantId: string,
  onVariantChange: (variant: Variant) => void,
  domRoots: { fields: HTMLElement | null; preview: HTMLElement | null },
  designId: string | undefined,
  selectedGelatoVariantId: string | undefined,
  allVariantImages: undefined | VariantImages,
  settings: { isDevHost?: boolean; UIOverrides?: UIOverrides; hidePreviewScrollBar?: boolean } = {},
) {
  if (!domRoots.fields) {
    return;
  }
  product.variants.forEach((variant) => {
    const { designData } = variant;
    if (designData) {
      designData.spreads = designData.spreads.filter((spread) => !!spread.pages.length);
      variant.designData = JSON.parse(JSON.stringify(designData));
    }
  });

  if (currentVariantId === selectedVariantId) {
    return;
  }
  currentVariantId = selectedVariantId;
  const selectedVariant = selectedGelatoVariantId
    ? product.variants.find((variant) => variant.gelatoVariantId === selectedGelatoVariantId)
    : product.variants.find((variant) => variant.id === selectedVariantId);
  gelatoVariantId = selectedVariant?.gelatoVariantId;

  if (settings?.isDevHost) {
    void fetchAndLoadDummyDesign();
  } else {
    await fetchAndLoadDesign(currentVariantId, selectedVariant?.designData, designId);
  }

  setProductImages(getVariantImages({ product, selectedVariantId, selectedGelatoVariantId, allVariantImages }));

  if (domRoots.preview) {
    const showStaticPreview = product?.showStaticPreview;
    const isMobile = mobileDevices.has(getCustomerDeviceType());
    if (!isEmpty(allVariantImages)) {
      const selectedVariantIndex = product.variants?.findIndex(
        (variant) => variant.gelatoVariantId === selectedGelatoVariantId,
      );
      store.dispatch(setSelectedPreviewIndexAction(selectedVariantIndex));
    }

    domRoots.preview.style.display = 'flex';
    domRoots.preview.style.justifyContent = 'center';
    domRoots.preview.style.alignItems = 'flex-start';
    domRoots.preview.style.height = isMobile ? '' : '100%';

    ReactDOM.render(
      <Sentry.ErrorBoundary>
        <React.StrictMode>
          <Provider store={store}>
            <Suspense fallback="">
              <PreviewAssetAPIContext.Provider value={previewAssetAPIEmbedded}>
                {showStaticPreview ? (
                  <StaticPreviewView />
                ) : (
                  <PreviewView
                    loadResource={!settings?.isDevHost}
                    hidePreviewScrollBar={settings.hidePreviewScrollBar}
                  />
                )}
              </PreviewAssetAPIContext.Provider>
            </Suspense>
          </Provider>
        </React.StrictMode>
      </Sentry.ErrorBoundary>,
      domRoots.preview,
    );
  }

  async function onVariantChangeHandler(variant: Variant) {
    if (variant.gelatoVariantId && gelatoVariantId !== variant.gelatoVariantId) {
      await fetchAndLoadDesign(variant.gelatoVariantId, variant.designData);
      gelatoVariantId = variant.gelatoVariantId;
    } else if (currentVariantId !== variant.id) {
      await fetchAndLoadDesign(variant.id, variant.designData);
    }
    if (allVariantImages && !isEmpty(allVariantImages)) {
      const newImages = getVariantImages({
        product,
        selectedVariantId: variant.id,
        selectedGelatoVariantId: variant.gelatoVariantId,
        allVariantImages,
      });

      setProductImages(newImages);
    }
    currentVariantId = variant.id;
    onVariantChange(variant);
  }

  ReactDOM.render(
    <Sentry.ErrorBoundary>
      <React.StrictMode>
        <Provider store={store}>
          <Suspense fallback="">
            <BottomBarHeightProvider>
              <PersonalizationView
                product={product}
                onVariantChange={onVariantChangeHandler}
                initialVariant={selectedVariant}
                overrides={settings.UIOverrides}
                noTopBorder={false}
                updateImage={isEmpty(allVariantImages)}
              />
            </BottomBarHeightProvider>
          </Suspense>
          <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
          <DropDownContainer />
          <TooltipContainer />
        </Provider>
      </React.StrictMode>
    </Sentry.ErrorBoundary>,
    domRoots.fields,
  );
}

const wrappedDraftAPI = {
  loadDrafts: draftAPI.loadDrafts.bind(null, store),
  getDraftsData: draftAPI.getDraftsData.bind(null, store),
  createDraft: draftAPI.createDraft.bind(null, store),
  updateDraft: debounce(draftAPI.updateDraft.bind(null, store), 10000, { leading: true, trailing: true }),
  deleteDraft: draftAPI.deleteDraft.bind(null, store),
  convertToDesignAndDelete: draftAPI.convertToDesignAndDelete.bind(null, store),
};

(window as any).draftAPI = wrappedDraftAPI;

function setLocale(language: string) {
  const locale = languageMap[language] ?? 'en';
  if (locale && locale !== i18n.language) {
    void i18n.changeLanguage(locale);
  }
}

async function renderShippingCost(options: {
  country: string;
  currency: string;
  quantity: string;
  variantId: string;
  divHTMLContainer: HTMLDivElement;
}) {
  const { country, currency: requestCurrency, quantity, variantId, divHTMLContainer } = options;

  const prices = await fetchShippingPrices(requestCurrency, country, variantId, quantity);
  const { price, currency, minDays, maxDays } = prices.products[0].prices?.[0]?.methods?.[0] || {};
  if (!price || !currency || !minDays || !maxDays) {
    // eslint-disable-next-line no-console
    console.error(`Can not find price in response ${JSON.stringify(prices)}`);
    return;
  }

  ReactDOM.render(
    <Sentry.ErrorBoundary>
      <React.StrictMode>
        <I18nextProvider i18n={i18n}>
          <Suspense fallback="">
            <ShippingPrice price={price} currency={currency} minDays={minDays} maxDays={maxDays} />
          </Suspense>
        </I18nextProvider>
      </React.StrictMode>
    </Sentry.ErrorBoundary>,
    divHTMLContainer,
  );
}

function renderEditItemInCartButton(divHTMLContainer: HTMLDivElement) {
  ReactDOM.render(
    <Sentry.ErrorBoundary>
      <React.StrictMode>
        <I18nextProvider i18n={i18n}>
          <Suspense fallback="">
            <EditCartItem />
          </Suspense>
        </I18nextProvider>
      </React.StrictMode>
    </Sentry.ErrorBoundary>,
    divHTMLContainer,
  );
}

function renderCartItemDescription(divHTMLContainer: HTMLDivElement, variant: ECommerceVariant) {
  ReactDOM.render(
    <Sentry.ErrorBoundary>
      <React.StrictMode>
        <I18nextProvider i18n={i18n}>
          <Suspense fallback="">
            <CartItemDescription variant={variant} />
          </Suspense>
        </I18nextProvider>
      </React.StrictMode>
    </Sentry.ErrorBoundary>,
    divHTMLContainer,
  );
}

function renderOpenDraftsButton(divHTMLContainer: HTMLDivElement, customerId: string | undefined, classNames: string) {
  divHTMLContainer.className = classNames;

  function OpenDraftsButton() {
    const [showSidebar, setShowSidebar] = useState(false);
    const customerReferenceId = customerId || getGuestCustomerId();

    useEffect(() => {
      if (showSidebar) {
        let draftRoot = document.getElementById('draft-root');

        if (!draftRoot) {
          draftRoot = document.createElement('div');
          draftRoot.id = 'draft-root';
          document.body.appendChild(draftRoot);
        }

        ReactDOM.render(
          <Sentry.ErrorBoundary>
            <React.StrictMode>
              <I18nextProvider i18n={i18n}>
                <Provider store={store}>
                  <Suspense fallback="">
                    <DraftSidebar customerReferenceId={customerReferenceId} />
                  </Suspense>
                </Provider>
              </I18nextProvider>
            </React.StrictMode>
          </Sentry.ErrorBoundary>,
          draftRoot,
        );
      }
    }, [showSidebar, customerReferenceId]);

    const handleClick = () => {
      void draftAPI.loadDrafts(store, customerReferenceId);
      setShowSidebar(true);
      store.dispatch(toggleSidebarAction());
    };

    return (
      <Sentry.ErrorBoundary>
        <React.StrictMode>
          <I18nextProvider i18n={i18n}>
            <Suspense fallback="">
              <OpenDraftsItem onClick={handleClick} />
            </Suspense>
          </I18nextProvider>
        </React.StrictMode>
      </Sentry.ErrorBoundary>
    );
  }

  ReactDOM.render(
    <Provider store={store}>
      <OpenDraftsButton />
    </Provider>,
    divHTMLContainer,
  );
}

function renderConfirmationPopup(divHTMLContainer: HTMLDivElement) {
  ReactDOM.render(
    <Sentry.ErrorBoundary>
      <React.StrictMode>
        <Provider store={store}>
          <Suspense fallback="">
            <PersonalizerConfirmationPopup />
          </Suspense>
        </Provider>
      </React.StrictMode>
    </Sentry.ErrorBoundary>,
    divHTMLContainer,
  );
}

function openCartDeleteConfirmationPopup({
  customizationId,
  variantId,
  customerReferenceId,
  onConfirm,
}: {
  customizationId: string | undefined;
  variantId: string | undefined;
  customerReferenceId: string | undefined;
  onConfirm: () => void;
}) {
  const { drafts } = store.getState();
  let suggestDraftCreation = false;
  if (customizationId && variantId) {
    const draftExists = drafts?.drafts.some(
      (draft) => draft.designId === customizationId && draft.variantId === variantId,
    );
    suggestDraftCreation = !draftExists;
  }

  const { t } = i18n;
  const popupTitle = t('Would you like to delete this product?');
  let textLines = [t('If you delete it permanently, it won’t be accessible anymore.')];

  const options: ConfirmationOption[] = [
    {
      title: t('Cancel'),
    },
  ];

  if (suggestDraftCreation && customizationId && variantId) {
    textLines = [t('You can either delete it permanently or move it to drafts.')];
    options.push({
      title: t('Move to drafts'),
      onConfirm: () => {
        fetchDesignFromId(customizationId)
          .then((design) => {
            const designData = design.structure && (JSON.parse(design.structure) as DesignData | undefined);
            if (!designData) {
              throw new Error('Design data is not defined');
            }
            return designData;
          })
          .then((designData) => {
            if (designData) {
              return createDraft(store, designData, variantId, undefined, customerReferenceId);
            }
            return undefined;
          })
          .then(() => onConfirm())
          .catch((error) => {
            throw new Error(`An error occurred: ${error}`);
          });
      },
    });
  }

  options.push({
    title: t('Delete permanently'),
    onConfirm,
  });

  store.dispatch(
    openPopupOperation(PopupName.LOCAL_CONFIRMATION_POPUP, {
      popupTitle,
      textLines,
      options,
      hideTitleIcon: true,
    }),
  );
}

function renderProductPageCTAButtonLabel(divHTMLContainer: HTMLDivElement, defaultLabel: DefaultCtaButtonText) {
  ReactDOM.render(
    <Sentry.ErrorBoundary>
      <React.StrictMode>
        <I18nextProvider i18n={i18n}>
          <Suspense fallback="">
            <CtaButtonLabel defaultButtonText={defaultLabel} />
          </Suspense>
        </I18nextProvider>
      </React.StrictMode>
    </Sentry.ErrorBoundary>,
    divHTMLContainer,
  );
}

let mounted = false;
function toggleDraftUI(customerId: string | undefined) {
  const customerReferenceId = customerId || getGuestCustomerId();
  void draftAPI.loadDrafts(store, customerReferenceId);

  if (!mounted) {
    mounted = true;
    const draftRoot = document.createElement('div');
    draftRoot.id = 'draft-root';
    document.body.appendChild(draftRoot);

    ReactDOM.render(
      <Sentry.ErrorBoundary>
        <React.StrictMode>
          <I18nextProvider i18n={i18n}>
            <Provider store={store}>
              <Suspense fallback="">
                <DraftSidebar customerReferenceId={customerReferenceId} />
              </Suspense>
            </Provider>
          </I18nextProvider>
        </React.StrictMode>
      </Sentry.ErrorBoundary>,
      draftRoot,
    );
  }

  store.dispatch(toggleSidebarAction());
}

export {
  saveDesign,
  loadProduct,
  wrappedDraftAPI as draftAPI,
  renderShippingCost,
  renderOpenDraftsButton,
  getPreviewUrl,
  renderEditItemInCartButton,
  renderCartItemDescription,
  renderConfirmationPopup,
  openCartDeleteConfirmationPopup,
  renderProductPageCTAButtonLabel,
  setLocale,
  fetchDesignFromId as getDesignById,
  toggleDraftUI,
};
