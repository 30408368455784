import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';
import { GridEvent } from 'editor/src/store/gridConfig/types';

import createCalendarEventId from 'editor/src/util/reflectDesignData/createCalendarEventId';

const addCalendarPublicEventsReducer = (state: DesignState, action: PayloadAction<GridEvent[]>) => {
  if (!state.designData?.calendar?.events) {
    return;
  }
  const eventsList = state.designData.calendar.events;
  action.payload.forEach(({ date, title }) => {
    const eventDate = new Date(date);
    const eventId = createCalendarEventId(title, eventDate, false);
    if (!eventsList.find(({ id }) => eventId === id)) {
      eventsList.push({
        id: eventId,
        day: eventDate.getDate(),
        month: eventDate.getMonth(),
        year: eventDate.getFullYear(),
        label: title,
        checked: false,
        custom: false,
      });
    }
  });
  state.designData.calendar.events = eventsList;
};

export default addCalendarPublicEventsReducer;
