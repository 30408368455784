import { GridDate } from '@gelatoas/design-editor-calendar';

import type { LayoutGeneratedTextType } from 'editor/src/store/editorModules/layouts/types';
import { ExportedWarning } from 'editor/src/store/editorModules/warnings/types';
import { TextAlign, TextDirection } from 'editor/src/store/fonts/types';
import { ImageColors } from 'editor/src/store/gallery/types';
import { MockupRole, MockupVariant } from 'editor/src/store/mockup/types';
import { PluginName } from 'editor/src/store/plugins/types';

export const IMAGE_CORRUPTED = 'IMAGE_CORRUPTED';

export enum ColorSpace {
  sRGB = 'rgb',
  CMYK = 'cmyk',
}

export interface DesignState {
  originalDesignData?: DesignData;
  designData?: DesignData;
  innerPagesCount?: number;
  spreadBackgrounds?: SpreadGroundImage[];
  spreadForegrounds?: SpreadGroundImage[];
  autoApplyLayoutSchemaNames?: string[];
  designDataTouched: boolean;
  digitizedAssets: {
    [id: string]: DigitizedAsset;
  };
  globalPlugins: string[];
  designSavingStatus: SavingDesignStatus;
}

export type DigitizedAsset = DigitizedAssetImage | DigitizedAssetAddon | DigitizedAssetText;
export type DigitizedElementType = DigitizedAsset['elementType'];

export interface DigitizedAssetBase {
  url: string;
  imageId: string;
  stitchCount: number;
}

export interface DigitizedAssetImage extends DigitizedAssetBase {
  elementType: 'image';
  payload: DigitizeImagePayload;
}

export interface DigitizedAssetAddon extends DigitizedAssetBase {
  elementType: 'addon';
  payload: DigitizeImagePayload;
}

export interface DigitizedAssetText extends DigitizedAssetBase {
  elementType: 'text';
  margin: number;
  payload: DigitizeTextPayload;
}

export type DigitizedAssetFromType<T extends DigitizedAsset['elementType']> = T extends 'text'
  ? DigitizedAssetText
  : T extends 'image'
    ? DigitizedAssetImage
    : T extends 'addon'
      ? DigitizedAssetImage
      : never;

export interface DigitizeImagePayload {
  imageUrl: string;
  rendered_mm_width: number;
  output_px_width: number;
  elementUuid: number;
  variantKey: string | undefined;
}

export interface DigitizeTextPayload {
  textElement: MediaText;
  path: string;
  output_px_width: number;
  elementUuid: number;
  variantKey: string | undefined;
}

export interface DesignDimensions {
  width: number;
  height: number;
  min_width: number;
  min_height: number;
  max_height: number;
  max_surface: number | undefined;
  max_width?: number;
}

export interface DimensionRestrictions {
  min_width: number;
  min_height: number;
  max_width: number;
  max_height: number;
}

export interface SpreadGroundImage {
  name: string;
  url: string;
  width: number;
  height: number;
  thumbnailUrl: string;
  thumbnailWidth: number;
  thumbnailHeight: number;
  top: number;
  left: number;
  imgWidth: number;
  imgHeight: number;
}

export interface DesignDataMetadata {
  created_at: string;
  updated_at: string;
  edit_session_count: number;
  edit_duration_seconds: number;
}

export interface CalendarEvent {
  id: string;
  day: number;
  month: number;
  year: number;
  label: string;
  checked: boolean;
  custom: boolean;
}

export interface Calendar {
  events?: CalendarEvent[];
}

export interface DesignData {
  product_uid: string;
  spreads: Spread[];
  page_count?: number;
  page_count_limit?: PageCountLimit;
  design_family_id?: string | number;
  design_template_id?: string | number;
  plugins?: string[];
  dimensions?: DesignDimensions;
  related_dimensions?: DesignDimensions;
  spread_groups?: SpreadGroup[];
  resolution?: {
    dpi: DpiLevels;
  };
  personalizationSettings?: PersonalizationSettings;
  lifecycle_metadata?: DesignDataMetadata;
  color_space?: ColorSpace;
  calendar?: Calendar;
}

export interface PersonalizationSettings {
  allowAddElements?: true;
  allowRemoveElements?: true;
}

interface DpiLevels {
  min: number;
  ok: number;
  max: number;
}

export interface ExportedDesignData extends DesignData {
  warnings?: ExportedWarning[];
}

export type ConditionChild = { type: 'condition'; id: string } | { type: MediaElement['type']; id: number };
export type ConditionAddress = { spreadIndex: number; conditionId: string };
export enum ConditionDisplayOption {
  Button = 'button',
  Dropdown = 'dropdown',
  Thumbnail = 'thumbnail',
}

export interface Condition {
  id: string;
  parent: { conditionId: string; optionIds: string[] } | undefined;
  name: string;
  displayOption: ConditionDisplayOption;
  options: ConditionOption[];
  activeOptionId: string;
}

export interface ConditionOption {
  id: string;
  label: string;
  imageId: string | undefined;
}

export const conditionGroupChildenKey = 'top';

export interface ConditionGroup {
  conditions: { [conditionId: string]: Condition };
  children: { [id: string]: ConditionChild[] }; // conditionGroupChildenKey | conditionId + optionId
}

export enum PrintTechnology {
  DTG = 'DTG',
  Embroidery = 'Embroidery',
}

export interface Spread {
  x: number;
  y: number;
  pages: Page[];
  tags?: string[];
  name: string;
  isTemplate?: boolean;
  canAddAfter?: boolean;
  canRemove?: boolean;
  layoutSchemaName?: string;
  sceneId?: string;
  conditionGroup?: ConditionGroup;
  technology?: PrintTechnology;
  spot_finishing_type?: SpotFinishingType;
}

export interface PageCountLimit {
  min: number;
  max: number;
  increment: number;
}

export interface Page {
  spread: string;
  x: number;
  y: number;
  width: number;
  height: number;
  page_nr: number;
  groups: {
    bleed?: Bleed[];
    mediabox?: MediaBox[];
    content?: Content[];
    media?: MediaElement[];
    diecut?: Diecut[];
    foldingline?: FoldingLine[];
    blank?: Blank[];
    hole?: Hole[];
  };
  backgroundColor?: string;
}

export interface GroupElement {
  type: string;
  name: string;
  x: number;
  y: number;
  width: number;
  height: number;
  extra?: any;
}

export interface Bleed extends GroupElement {
  type: 'border' | 'area';
  path?: string;
  thickness?: number;
}

export interface Hole extends GroupElement {
  type: 'circle';
  x: number;
  y: number;
  diameter: number;
}

export interface Diecut extends GroupElement {
  type: 'area';
  path: string;
  sx: number;
  sy: number;
}

export interface MediaBox extends GroupElement {
  type: 'rect';
}

export type MirroringMode = 'mirror' | 'blur';

export interface Content extends GroupElement {
  type: 'sample' | 'area' | 'panel';
  margin?:
    | {
        left: number;
        right: number;
        top: number;
        bottom: number;
      }
    | number;
  title?: string;
  description?: string[];
  rotate?: number;
  extra?: {
    mirroring?: MirroringMode;
  };
  path?: string;
}

export interface FoldingLine {
  type: 'line';
  name: string;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}

export type Blank = BlankArea;

export interface BlankCommon {
  extra: any;
  name: string;
}
export type BlankArea = BlankCommon & GroupArea;

export interface GroupLine {
  type: 'line';
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}

export interface GroupArea {
  type: 'area';
  path: 'string';
  width: number;
  height: number;
  x: number;
  y: number;
}

export type MediaElement = MediaAddon | MediaImage | MediaMockupPlaceholder | MediaText | MediaGrid | MediaShape;
export type MediaElementFromType<T extends MediaElement['type']> = T extends 'addon'
  ? MediaAddon
  : T extends 'image'
    ? MediaImage
    : T extends 'text'
      ? MediaText
      : T extends 'grid'
        ? MediaGrid
        : T extends 'line'
          ? MediaLine
          : T extends 'rectangle'
            ? MediaRectangle
            : never;

type ElementOwner = { type: 'content'; pageIndex: number; contentIndex: number } | { type: 'page'; pageIndex: number };

interface MediaElementType extends GroupElement {
  group: '';
  role?: string;
  uuid: number;
  r: number;
  locked?: true;
  personalizationLocked?: true;
  sample?: true;
  hidden?: true;
  static?: true;
  unduplicable?: true;
  pageOrigin?: {
    width: number;
    height: number;
  };
  layoutFrameId?: string;
  createdByLayout?: true;
  createdWhileEmbedded?: true;
  belongs_to?: ElementOwner;
  stitch_count?: number;
  digitizing?: true;
  digitizing_hash?: string;
  has_spot_finishing?: true;
}

export interface PerspectiveTransform {
  tl_x: number;
  tl_y: number;
  tr_x: number;
  tr_y: number;
  bl_x: number;
  bl_y: number;
  br_x: number;
  br_y: number;
}

interface MediaImageBase extends MediaElementType {
  px: number;
  py: number;
  pw: number;
  ph: number;
  pr: number;
  flipX?: true;
  flipY?: true;
  colorOverrides?: ImageBrushData;
  cropOrigin?: CropOrigin;
  perspective_transform?: PerspectiveTransform;
  uploading?: true; // true when the asset is being uploaded or imported
}

export interface MediaImage extends MediaImageBase {
  type: 'image';
  url?: string;
  imageId: string;
  sourceAssetId?: string; // the asset id of the original image if it was generated from it
  adjustments?: Adjustment[];
  filters?: Filter[];
  pattern?: ImagePattern;
  plugins?: any;
  linkId?: string;
  minDimensions?: Dimensions;
  sourceFrame?: Dimensions;
  shadow?: DropShadow | LeaningShadow;
  loading?: true; // true when the asset is in loading state or waiting for some changes
}

export interface MediaAddon extends MediaImageBase {
  type: 'addon';
  addonId: string;
  url: string;
}

export enum AnchoringX {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum AnchoringY {
  Top = 'top',
  Middle = 'middle',
  Bottom = 'bottom',
}

export enum MockupDisplayMode {
  Hanging = 'hanging',
  Leaning = 'leaning',
}
export interface MediaMockupPlaceholder extends Omit<MediaImage, 'role'> {
  type: 'image';
  role: MockupRole.Placeholder;
  variant: MockupVariant;
  area_fit?: true;
  anchoring_x?: AnchoringX;
  anchoring_y?: AnchoringY;
  shadow?: DropShadow | LeaningShadow;
  display_mode?: MockupDisplayMode;
}

export interface ProductImage {
  url: string;
  width: number;
  height: number;
}

export type CropOrigin = {
  px: number;
  py: number;
  pw: number;
  ph: number;
  width: number;
  height: number;
};

export enum Direction {
  Horizontal = 'hor',
  Vertical = 'ver',
}

export type PatternScaleBase = 'width' | 'height';
export interface BrickPattern {
  type: 'brick';
  direction: Direction;
  scale: number;
  offset?: number;
  spacing?: number;
  mirror?: true;
  scaleBase?:
    | { type: 'element'; value: PatternScaleBase }
    | { type: 'width'; value: number }
    | { type: 'height'; value: number }
    | undefined;
}

export type ImagePattern = BrickPattern;

export interface ImageManipulationPluginData {
  imageModified: true;
}

export interface ColorOverrides {
  previous: string;
  new: string;
}

export interface ImageBrushData {
  fill?: ColorOverrides[];
  stroke?: ColorOverrides[];
  url?: string;
  urlTimestamp?: number;
}

export interface Adjustment {
  name: string;
  value: number;
}

export interface Filter {
  name: string;
  value: number;
}

export interface MediaGridInfo {
  designName: string;
  locale: string;
  gridDate: GridDate;
  firstDayOfWeek?: number;
  hideWeekNum?: true;
}

export interface MediaGrid extends MediaElementType {
  type: 'grid';
  content: string; // svg
  grid: MediaGridInfo;
  gridHash?: string;
}

export function isMediaImage(elt: MediaElement): elt is MediaImage {
  return elt.type === 'image';
}

export enum TextWrapping {
  Wrap = 'wrap',
  Fit = 'fit',
  FitOneLine = 'fit-one-line',
}

export interface MediaText extends MediaElementType {
  type: 'text';
  sx: number;
  sy: number;
  fill: string;
  rendered_with: 'opentype.js';
  uneditable?: true;
  html?: string;
  generatedType?: LayoutGeneratedTextType;
  extra: {
    text: string;
    fontSize: number;
    fontFamily: string;
    textAlign?: TextAlign;
    lineHeight?: number;
    underline?: boolean;
    linethrough?: boolean;
    direction?: TextDirection;
    letterSpacing?: number;
    stroke?: Stroke;
    curve?: number;
    shadow?: DropShadow;
    flexibleWidth?: boolean;
    wrapping?: TextWrapping;
    maxCharCount?: number;
  };
  stitch_count?: number;
  sourceFrame?: Dimensions;
  uploading?: true; // true when the digitized version is being uploaded or imported
}

export interface Stroke {
  color: string;
  width: number;
}

export interface DropShadow {
  type: 'drop-shadow';
  offsetX: number;
  offsetY: number;
  blur: number;
  color: string;
  scale?: number;
  absolute_direction?: true;
}

export type ShadowSide = 'left' | 'right';

export interface LeaningShadow {
  type: 'leaning-shadow';
  blur: number;
  color: string;
  side?: ShadowSide;
}

export type LineEdge = 'flat' | 'arrow' | 'arrow-fill';

export type MediaShape = MediaLine | MediaRectangle;

export type DashPattern = { dash: number; gap: number };

export interface MediaShapeBase {
  strokeWidth: number;
  uuid: number;
  locked?: true;
  personalizationLocked?: true;
  unduplicable?: true;
  name: string;
  hidden?: true;
  static?: true;
  pageOrigin?: {
    width: number;
    height: number;
  };
  createdWhileEmbedded?: true;
  belongs_to?: ElementOwner;
  stitch_count?: number;
  digitizing?: true;
  digitizing_hash?: string;
  has_spot_finishing?: true;
}

export interface MediaLine extends MediaShapeBase {
  type: 'line';
  stroke: string;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  edge1: LineEdge;
  edge2: LineEdge;
  dashPattern?: DashPattern;
  rounded: boolean;
}

export interface MediaRectangle extends MediaShapeBase {
  type: 'rectangle';
  x: number;
  y: number;
  width: number;
  height: number;
  r: number;
  cornerRounding?: number;
  fill?: string;
  stroke?: string;
  dashPattern?: DashPattern;
}

export interface ElementAddress {
  spreadIndex: number;
  pageIndex: number;
  elementIndex: number;
}

export interface ContentAddress {
  spreadIndex: number;
  pageIndex: number;
  contentIndex: number;
}

export interface StructureIndex extends ElementAddress {
  uuid: number;
}

export interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface ElementRect extends Rect {
  r: number;
}

export interface ContentRect extends Rect {
  type: string;
}

export interface Coords {
  left: number;
  top: number;
}

export interface Dimensions {
  width: number;
  height: number;
}

export type SpreadGroupPosition = 'vertical' | 'horizontal' | 'center';

export interface SpreadGroup {
  spreadIndexes: number[];
  position: SpreadGroupPosition;
  name: string;
  layoutTag?: string;
}

export interface SpreadInfo {
  spreadIndex: number;
  spreadWidth: number;
  spreadHeight: number;
  spreadCoords: Coords;
}

export interface UploadedAsset {
  pluginAssetId: string;
  assetId: string;
  url: string;
  thumbnailUrl: string;
  mimeType: string;
  colors?: ImageColors;
  quantizedColors?: string[];
  width: number;
  height: number;
  label: string;
}

export interface ReflectDesignDataConfig {
  resetMedia?: boolean;
  ignore?: {
    plugins: (PluginName | string)[];
    elementTypes: (MediaElement['type'] | MediaShape['type'])[];
  };
}

export enum SpotFinishingType {
  Gold = 'foil-gold',
  Copper = 'foil-copper',
  Silver = 'foil-silver',
}

export interface ElementAddressesIndexedByNames {
  [keyof: string]: StructureIndex;
}

export type SavingDesignStatus = 'saving' | 'saved' | 'offline';
