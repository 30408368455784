import { createSlice } from '@reduxjs/toolkit';

import { WarningType } from 'editor/src/store/editorModules/warnings/types';

import environment from 'editor/src/environment';

import setHostSettingsReducer from './reducer/setHostSettingsReducer';
import { HostSettingsState } from './types';

export const DEFAULT_MEDIUM_DPI = 150;

export const hostSettingsInitialState: HostSettingsState = {
  showDoubleLine: false,
  linesColor: { main: 'rgba(51, 51, 51, 0.3)', outer: 'rgba(255,255,255,0.7)' },
  showPrintAreaLines: true,
  dpiLevels: { showHighToastr: false, showMediumToastr: false },
  changeImageFrameColorBasedOnDPI: true,
  backgroundColor: undefined,
  showCanvasOperationHelper: false,

  // wallpaper
  allowShowRollerStripesChange: false,
  allowShowRulersChange: false,
  allowShowPerspectiveLines: false,
  activateWallpaperEmptyScreen: false,
  resizeProductBasedOnFirstElement: false,
  repeatDesign: undefined,

  allowAddElements: true,
  allowRemoveElements: true,
  allowUseNotUploadedImages: true,
  autoApplyUploadedImages: false,
  allowLockElements: true,
  allowPersonalizationLock: false,
  allowSampleFlagChange: false,
  flagNewTextElementsAsSample: false,
  allowStaticFlagChange: false,
  allowSpreadBackgroundColorChange: false,
  ignorePersonalizationLock: true,
  allowAutofill: true,
  allowSelectMultipleImages: false,
  allowLockedImageElementDeletion: true,
  defaultPersonalizationLock: true,
  deleteFrameWithImage: false,
  splitDisplayPerContent: false,
  enableMirroring: false,
  hideAddonsSearch: false,
  editImageFlag: false,
  enableCalendarEvents: false,
  showCroppedImageArea: false,
  enableHelperCenter: undefined,
  enableImageShadow: false,
  enableMockupLeaning: false,
  enablePreflightGeneratedProductMockupImage: false,
  showDesignScroll: false,
  enableProductFitting: true,
  digitizationRules: {
    maxColorsCount: 6,
    maxStitchCount: 15000,
  },
  previews: {
    includeBackground: true,
    includeForegound: false,
    firstSpreadOnly: false,
    noFlatPreviews: true,
  },
  perSpreadPreviewAvailable: false,

  coreFeatures: {
    imageCrop: true,
    imageCropRotate: true,
    imageCropZoom: true,
    imageFlip: true,
    imagePerspectiveTransform: false,
  },

  enabledWarnings: {
    [WarningType.LowDPI]: true,
    [WarningType.NotVisible]: true,
    [WarningType.OutOfRange]: true,
    [WarningType.MaxStitchCount]: false,
    [WarningType.MaxColorCount]: false,
    [WarningType.EmptyPage]: false,
    [WarningType.EmptyDesign]: false,
    [WarningType.EmptyImageBox]: false,
    [WarningType.SampleElementNotReplaced]: true,
  },
  showWarningsOnlyAfterDesignRequest: false,
  hiddenWarnings: {},
  colorPickerConfig: {
    enableOpacity: false,
  },
  minFontSize: 1,
  maxFontSize: 2000,
  showEmptyDesignPlaceholder: false,
  defaultElementPositioning: 'fill',

  ignoreDesignPlugins: false,
  designOptions: false,
  enableSpreadsView: false,
  enableEditorHeader: false,
  variantsLimit: undefined,
  defaultPersonalizationPlugin: false,
  editorMode: undefined,
  allowCurvedText: false,
  variantSwitchConfirmationEnabled: false,
  allowMultipleColorSpaces: false,

  ...environment.hostSettingsOverrides,
};

const slice = createSlice({
  name: 'hostSettings',
  initialState: hostSettingsInitialState,
  reducers: {
    setHostSettingsAction: setHostSettingsReducer,
  },
});

const { actions, reducer } = slice;

export const { setHostSettingsAction } = actions;

export default reducer;
