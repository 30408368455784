import { fabric } from 'fabric';
import { MultiPolygon } from 'polygon-clipping';

import {
  CalendarEvent,
  Content,
  ContentAddress,
  Coords,
  DesignState,
  Dimensions,
  Page,
  Spread,
  SpreadGroup,
} from 'editor/src/store/design/types';

import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import addElements from './addElements';
import addSpreadImage from './addSpreadImage';
import { DataSource, SpreadGroupInfo } from './createSpreadPreview';

const addSpreadMediaAndImages = async (
  fabricCanvas: fabric.StaticCanvas,
  mm2px: (size: number) => number,
  previewWidth: number,
  previewHeight: number,
  offset: Coords,
  dataSources: DataSource,
  quality: number,
  rotation: CanvasRotation,
  showEmptyImages: boolean,
  useThumbnails: boolean,
  spread: Spread,
  visibleAreaPolygons: { fullArea: MultiPolygon; contentArea: MultiPolygon },
  digitizedAssets: DesignState['digitizedAssets'],
  loadFont: (font: string) => Promise<void>,
  contentClipPath: fabric.Object | undefined,
  focusedContent?: Content,
  focusedPage?: Page,
  focusedContentAddress?: ContentAddress,
  spreadGroupInfo?: SpreadGroupInfo[],
  spreadGroup?: SpreadGroup,
  calendarEvents?: CalendarEvent[],
) => {
  let foregroundDim: Dimensions | undefined;
  let backgroundDim: Dimensions | undefined;

  if (spreadGroupInfo) {
    await Promise.all(
      spreadGroupInfo.map(async (groupInfo) => {
        const spreadGroupOffset = {
          left: offset.left + mm2px(groupInfo.spreadOffsetCoords.left),
          top: offset.top + mm2px(groupInfo.spreadOffsetCoords.top),
        };
        backgroundDim = await addSpreadImage(
          fabricCanvas,
          groupInfo.backgroundImage,
          zIndex.BACKGROUND,
          spreadGroupOffset,
          mm2px,
          groupInfo.spreadWidth,
          groupInfo.spreadHeight,
          useThumbnails,
        );
        foregroundDim = await addSpreadImage(
          fabricCanvas,
          groupInfo.foregroundImage,
          zIndex.FOREGROUND,
          spreadGroupOffset,
          mm2px,
          groupInfo.spreadWidth,
          groupInfo.spreadHeight,
          useThumbnails,
        );
      }),
    );
  } else {
    backgroundDim = await addSpreadImage(
      fabricCanvas,
      dataSources.backgroundImage,
      zIndex.BACKGROUND,
      offset,
      mm2px,
      previewWidth,
      previewHeight,
      useThumbnails,
    );
    foregroundDim = await addSpreadImage(
      fabricCanvas,
      dataSources.foregroundImage,
      zIndex.FOREGROUND,
      offset,
      mm2px,
      previewWidth,
      previewHeight,
      useThumbnails,
    );
  }
  const spreadOffsetCoords = spreadGroupInfo?.[0].spreadOffsetCoords;
  // we need to add the offset in case of grouped spreads.
  const mainGroupSpreadOffset = {
    left: offset.left + mm2px(spreadOffsetCoords?.left ?? 0),
    top: offset.top + mm2px(spreadOffsetCoords?.top ?? 0),
  };

  // only add the elements for main spread in case of grouped or non-grouped spreads.
  const dispose = await addElements(
    fabricCanvas,
    spread,
    dataSources,
    quality,
    mainGroupSpreadOffset,
    focusedPage && focusedContent && focusedContentAddress
      ? { focusedPage, focusedContent, address: focusedContentAddress }
      : undefined,
    mm2px,
    rotation,
    showEmptyImages,
    useThumbnails,
    visibleAreaPolygons,
    digitizedAssets,
    loadFont,
    contentClipPath,
    spreadGroup,
    spreadOffsetCoords,
    calendarEvents,
  );

  return { foregroundDim, backgroundDim, dispose };
};

export default addSpreadMediaAndImages;
