import { captureException } from '@sentry/react';

import { DesignData } from 'editor/src/store/design/types';

import { PPStore } from './store';
import deleteDraftOperation from './store/drafts/operations/deleteDraftOperation';
import loadDraftsOperation from './store/drafts/operations/loadDraftsOperation';
import { Draft, addDraftAction, deleteDraftAction, updateDraftAction } from './store/drafts/slice';
import { getGuestCustomerId } from './store/drafts/utils/guestStorage';
import { DraftDesignItemResponse, getMetadataPayload, getMetadataValue } from './utils/draftDesign';

export async function loadDrafts(store: PPStore, customerId: string | undefined): Promise<void> {
  const customerReferenceId = customerId || getGuestCustomerId();
  const drafts = await getDraftsList(customerReferenceId);
  return store.dispatch(loadDraftsOperation(drafts, customerReferenceId));
}

export function getDraftsData(store: PPStore) {
  return store.getState().drafts;
}

const defaultDesignParams = { status: 'draft', accessMode: 'public' };

export async function getDraftsList(customerReferenceId: string) {
  const url = `${LOCAL_ENV.ecomProxyEndpoint}/designs?customerReferenceId=${customerReferenceId}`;
  try {
    const res = await fetchWithRetry(url, { method: 'GET' });
    const { designs } = (await res.json()) as { designs: DraftDesignItemResponse[] };
    const drafts: Draft[] = [];
    designs.forEach((design) => {
      const externalVariantId = getMetadataValue(design.metadata, 'storeProductVariantId');
      const variantId = getMetadataValue(design.metadata, 'gelatoVariantId');

      if (externalVariantId) {
        drafts.push({
          designId: design.id,
          externalVariantId,
          variantId,
        });
      } else {
        captureException(`externalVariantId not found for design with id=${design.id}`);
      }
    });

    return drafts;
  } catch (error) {
    captureException(`Failed to fetch drafts for customerReferenceId=${customerReferenceId}: ${error.message}`, error);
    throw error;
  }
}

export async function createDraft(
  store: PPStore,
  designData: DesignData,
  storeProductVariantId: string,
  gelatoVariantId: string | undefined,
  customerId: string | undefined,
): Promise<Draft> {
  // const variantId = gelatoVariantId || externalVariantId;
  if (!gelatoVariantId && !storeProductVariantId) {
    throw new Error('No variant id provided');
  }

  const res = await fetch(`${LOCAL_ENV.ecomProxyEndpoint}/designs`, {
    method: 'post',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      structure: JSON.stringify(designData),
      customerReferenceId: customerId || getGuestCustomerId(),
      metadata: getMetadataPayload(storeProductVariantId, gelatoVariantId),
      ...defaultDesignParams,
    }),
  });

  const { id: designId } = (await res.json()) as { id: string };
  const draft: Draft = {
    designId,
    externalVariantId: storeProductVariantId,
    variantId: gelatoVariantId,
  };
  store.dispatch(addDraftAction(draft));
  return draft;
}

export async function updateDraft(
  store: PPStore,
  designId: string,
  draftData: { variantId: string | undefined; externalVariantId: string },
  designData: DesignData,
  customerId: string | undefined,
): Promise<void> {
  const customerReferenceId = customerId || getGuestCustomerId();
  await sendUpdateDraftRequest(designId, draftData, customerReferenceId, JSON.stringify(designData));

  window.dispatchEvent(new CustomEvent('pp-draft-updated'));

  if (draftData.externalVariantId) {
    store.dispatch(
      updateDraftAction({
        designId,
        draft: { variantId: draftData.variantId, externalVariantId: draftData.externalVariantId },
      }),
    );
  }
}

export function sendUpdateDraftRequest(
  designId: string,
  draftData: { variantId: string | undefined; externalVariantId: string },
  customerReferenceId: string,
  structure: string,
) {
  return fetchWithRetry(`${LOCAL_ENV.ecomProxyEndpoint}/designs/${designId}`, {
    method: 'put',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      id: designId,
      customerReferenceId,
      metadata: getMetadataPayload(draftData.externalVariantId, draftData.variantId),
      structure,
      ...defaultDesignParams,
    }),
  });
}

export function deleteDraft(store: PPStore, designId: string): Promise<void> {
  return store.dispatch(deleteDraftOperation(designId));
}

export async function convertToDesignAndDelete(
  store: PPStore,
  designId: string,
  designData: DesignData,
): Promise<void> {
  await fetch(`${LOCAL_ENV.ecomProxyEndpoint}/designs`, {
    method: 'put',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ id: designId, structure: JSON.stringify(designData), status: 'created' }),
  });
  store.dispatch(deleteDraftAction(designId));
}

const fetchWithRetry = async (url: string, options: RequestInit, retries = 3, delay = 1000): Promise<Response> => {
  const timeout = (ms: number) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  } catch (error) {
    if (retries > 0) {
      await timeout(delay);
      return fetchWithRetry(url, options, retries - 1, delay);
    }
    throw error;
  }
};
