import { generateGridAsSVG, generatorUtils, GridDesign } from '@gelatoas/design-editor-calendar';

import { CalendarEvent, MediaGrid, Spread } from 'editor/src/store/design/types';

import createGridHash from '../design/createGridHash';
import { getCalendarEventsPerMonth } from '../layouts/getCalendarEventsPerMonth';

function updateGridElement(
  sourceElement: MediaGrid,
  destElement: MediaGrid,
  designs: GridDesign[],
  sourceSpread: Spread,
  destSpreadWith: number,
  calendarEvents?: CalendarEvent[],
) {
  // legacy: we used to have grid on the spread
  if (!sourceElement.grid && (sourceSpread as any).grid) {
    destElement.grid = { ...(sourceSpread as any).grid };
  }
  if (!destElement.grid) {
    return;
  }

  const { locale = 'en', designName, gridDate, firstDayOfWeek, hideWeekNum } = destElement.grid;
  const monthEvents = getCalendarEventsPerMonth(gridDate, locale, calendarEvents);

  const design = designs.find((design) => design.name === designName) || designs[0];
  destElement.content = generateGridAsSVG(
    design,
    gridDate,
    locale,
    destElement.width,
    destElement.height,
    monthEvents,
    {
      d2f: generatorUtils.getToSizeFn(design, destSpreadWith),
      firstDayOfWeek,
      hideWeekNum,
    },
  );
  destElement.gridHash = createGridHash(destElement.grid, monthEvents);
}

export default updateGridElement;
