import { designs } from '@gelatoas/design-editor-calendar';
import { createSlice } from '@reduxjs/toolkit';

import setGridDesignsReducer from './reducer/setGridDesignsReducer';
import { GridConfigState } from './types';

export const gridDesignsInitialState: GridConfigState = {
  grids: designs.gridDesigns,
};

export const slice = createSlice({
  name: 'gridDesigns',
  initialState: gridDesignsInitialState,
  reducers: {
    setGridDesignsAction: setGridDesignsReducer,
  },
});

export const { setGridDesignsAction } = slice.actions;

export default slice.reducer;
