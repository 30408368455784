import cloneDeep from 'lodash/cloneDeep';

import getSpreadWidthFromSpread from 'editor/src/store/design/selector/getSpreadWidthFromSpread';
import { DesignData, Page, Spread } from 'editor/src/store/design/types';
import { LayoutSchema } from 'editor/src/store/editorModules/layouts/types';

import getLayoutFrames from './getLayoutFrames';
import { MediaContext } from './reapplyFrameToTextElement';
import reflectMediaElement from './reflectMediaElement';
import { Mode } from './utils';

export function getMediaBoxes(sourceSpread: Spread, destSpread: Spread) {
  // element will be on the first page, but the first page may not have a mediabox, like a photobook Inside 1 spread
  const sourcePageWithMediaBoxIndex = sourceSpread.pages.findIndex((page) => !!page.groups.mediabox?.[0]);
  const sourcePageWithMediaBox = sourceSpread.pages[sourcePageWithMediaBoxIndex];
  if (!sourcePageWithMediaBox) {
    return undefined;
  }
  const destPageWithMediaBox =
    destSpread.pages[sourcePageWithMediaBoxIndex] ?? destSpread.pages.find((page) => !!page.groups.mediabox?.[0]);
  if (!destPageWithMediaBox) {
    return undefined;
  }

  const sourceBox = sourcePageWithMediaBox.groups.mediabox?.[0];
  const sourceMediaBox = sourceBox
    ? {
        x: sourceBox.x + sourcePageWithMediaBox.x,
        y: sourceBox.y + sourcePageWithMediaBox.y,
        width: sourceBox.width,
        height: sourceBox.height,
      }
    : sourcePageWithMediaBox;

  const destBox = destPageWithMediaBox.groups.mediabox?.[0];
  const destMediaBox = destBox
    ? {
        x: destBox.x + destPageWithMediaBox.x,
        y: destBox.y + destPageWithMediaBox.y,
        width: destBox.width,
        height: destBox.height,
      }
    : destPageWithMediaBox;

  return { sourceMediaBox, destMediaBox };
}

function reflectMediaElements(
  sourceSpread: Spread,
  sourcePage: Page,
  destSpread: Spread,
  layouts: LayoutSchema[],
  mode: Mode,
  spreadIndex: number,
  sourceDesign: DesignData,
  destDesign: DesignData,
  context?: MediaContext,
) {
  if (!sourcePage.groups.media) {
    return undefined;
  }

  const mediaBoxes = getMediaBoxes(sourceSpread, destSpread);
  if (!mediaBoxes) {
    return undefined;
  }

  const { sourceMediaBox, destMediaBox } = mediaBoxes;

  // fast path when pages are the same
  if (
    sourceMediaBox.width === destMediaBox.width &&
    sourceMediaBox.height === destMediaBox.height &&
    sourceMediaBox.x === destMediaBox.x &&
    sourceMediaBox.y === destMediaBox.y
  ) {
    return cloneDeep(sourcePage.groups.media);
  }

  const spreadGroup = sourceDesign.spread_groups?.find((spreadGroup) =>
    spreadGroup.spreadIndexes.includes(spreadIndex),
  );
  const sourceLayoutFrames = getLayoutFrames(sourceSpread, layouts, sourceDesign.spreads, spreadGroup);
  const destLayoutFrames = getLayoutFrames(destSpread, layouts, destDesign.spreads, spreadGroup);
  const destSpreadWidth = getSpreadWidthFromSpread(destSpread);

  return sourcePage.groups.media.map((sourceElement) =>
    reflectMediaElement(
      sourceMediaBox,
      destMediaBox,
      sourceLayoutFrames,
      destLayoutFrames,
      sourceSpread,
      destSpread,
      destSpreadWidth,
      sourceElement,
      mode,
      context,
      destDesign.calendar?.events,
    ),
  );
}

export default reflectMediaElements;
